import { latLng, icon } from "leaflet";

export const environment = {
    production: true,

    map_sw: latLng(53.474329, 19.36219),
    map_ne: latLng(53.703284, 19.778039),
    map_center: [53.584632, 19.570983],
    map_zoom: 15,

    logo: "",

    line_module: true,

    api_url: "https://ilawa.epasazer.pl:8446/ilsip/",
    server_url: "http://ilawa.epasazer.pl/",
    carrierSelectGuard: true,

    languages_de: true,
    one_carrier_only: true,

    stopIcon: icon({
        iconUrl: "assets/images/stop.png",
        iconSize: [32, 32],
    }),

    busIcon: icon({
        iconUrl: "assets/images/32x32.png",
        iconSize: [32, 32],
    }),

    carrier_name_overwrite: "ZKM Iława",
};
